/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SelectServices Component
 * Description: This component is used to select services. It displays a list of services with options to add or remove them. The component supports search, pagination, and lazy loading for improved performance. It also provides buttons to cancel or save the selected services.
 *
 * Props:
 * - selectedServices: IService[] - An array of initially selected services.
 * - modelStatus: string - The status of the model ('editservice' in this case).
 * - onClose: () => void - Callback function to close the modal.
 * - onSelect: (services: IService[]) => void - Callback function to handle the selection of services.
 * - submitLoading?: any - Loading state for submit action.
 *
 */

import { useEffect, useState } from 'react';
import { useSnackbar } from '@components/common';
import { serviceActions } from 'actions/service';
import { useAppDispatch } from 'hooks/redux';
import s from './SelectServices.module.scss';
import { IService } from 'interfaces/service.interface';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import { palette } from 'styled/common';
import { Spinner } from '@components/common';

const SelectServices = ({
    selectedServices,
    onClose,
    onSelect,
    id,
}: {
    selectedServices: any[];
    id: string;
    onClose(): void;
    onSelect(services: IService[]): void;
    submitLoading?: any;
}) => {
    const { t }: any = useTranslation();
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<IService[]>([]);
    const [services, setService] = useState<IService[]>([]);
    const [loading, setLoading] = useState(false);
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [openSnackbar] = useSnackbar();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [categories, setCategories] = useState<any[]>([]);

    useEffect(() => {
        getLabelServices();
    }, []);

    useEffect(() => {
        if (id !== undefined) {
            setLoading(true);
            api.get(`/serviceProvider/${id}`)
                .then((res: any) => {
                    setLoading(false);
                    if (res.data) {
                        setServiceProvider(res.data);
                    }
                })
                .catch(e => {
                    setLoading(false);
                });
        }
    }, [id]);

    useEffect(() => {
        onSelect(selected);
    }, [selected]);

    useEffect(() => {
        const selected: IService[] = [];
        if (services?.length) {
            if (selectedServices?.length) {
                selectedServices.forEach(service => {
                    selected.push(service);
                });
            }

            setSelected(selected);
        } else {
            dispatch(serviceActions.getServices()).then(res => {
                if (selectedServices?.length) {
                    selectedServices.forEach(service => {
                        selected.push(service);
                    });
                } else {
                }

                setSelected(selected);
            });
        }
    }, [dispatch]);

    const toggleSelect = (service: IService) => {
        const isSelected = selected.find(item => item._id === service._id);
        if (isSelected) {
            const newSelected = selected.filter(item => item._id !== service._id);
            setSelected(newSelected);
        } else {
            if (selected.length < 5) {
                setSelected([...selected, service]);
            } else {
                openSnackbar(t('Вы не можете выбрать более пяти сервисов'));
            }
        }
    };

    const select = (service: IService) => {
        toggleSelect(service);
    };

    const getLabelServices = async () => {
        setLoading(true);
        await api
            .get('/labeled-services')
            .then((res: any) => {
                setLoading(false);
                if (res.data) {
                    setCategories(res.data);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    // const groupedServices = serviceProvider?.services?.reduce((acc, service) => {
    //     const groupName = service.label_id || 'Uncategorized';
    //     if (!acc[groupName]) {
    //         acc[groupName] = [];
    //     }
    //     acc[groupName].push(service);
    //     return acc;
    // }, {} as Record<string, IService[]>);

    // const servicesArray = Object.entries(groupedServices || {});

    const groupServicesByCategory = (services: IService[], categories: any) => {
        return services.reduce((acc: Record<string, IService[]>, service) => {
            const category = categories.find((cat: any) => cat._id === service.label_id);
            const categoryName = category ? category.name : 'Без категории';
            if (!acc[categoryName]) {
                acc[categoryName] = [];
            }
            acc[categoryName].push(service);
            return acc;
        }, {});
    };

    return (
        <>
            <Container>
                <Row>
                    <ServicesContent>
                        <HeaderContainer>
                            <CloseButton onClick={onClose}>
                                <Icon className="fal fa-chevron-left"></Icon>
                            </CloseButton>
                            <Header>{t('Select Service')}</Header>
                        </HeaderContainer>
                        {id === undefined ? (
                            <div>{t('Please Select Service Provider First')}</div>
                        ) : (
                            <ServicesBody>
                                {loading ? (
                                    <Spinner color={'black'} />
                                ) : (
                                    serviceProvider &&
                                    (serviceProvider?.services?.length ? (
                                        <Wrapper>
                                            {Object.entries(
                                                groupServicesByCategory(
                                                    serviceProvider.services,
                                                    categories
                                                )
                                            )
                                                .sort(([a], [b]) => a.localeCompare(b))
                                                .map(([categoryName, services]) => (
                                                    <div key={categoryName}>
                                                        <GroupTitle>{categoryName}</GroupTitle>
                                                        <GroupWrapper>
                                                            {(services as IService[]).map(
                                                                (service: IService) => (
                                                                    <ServicesList key={service._id}>
                                                                        <div
                                                                            style={{
                                                                                height: '100%',
                                                                            }}
                                                                            className={`${
                                                                                s.services_service
                                                                            }
                                                            ${
                                                                selected?.find(
                                                                    item => item._id === service._id
                                                                )
                                                                    ? s.services_selectedService
                                                                    : ''
                                                            }
                                                            service-color-${service.color}--bd`}>
                                                                            <div
                                                                                style={{
                                                                                    height: '100%',
                                                                                }}
                                                                                onClick={() => {
                                                                                    select(service);
                                                                                }}
                                                                                className={`${s.service} service-color-${service.color}--bg cursor-pointer`}>
                                                                                <Label>
                                                                                    &nbsp;
                                                                                    {
                                                                                        _userData
                                                                                            .user
                                                                                            .business_id
                                                                                            .currency
                                                                                    }
                                                                                    {service.price}
                                                                                </Label>
                                                                                <CustomDiv>
                                                                                    <Name>
                                                                                        {
                                                                                            service.name
                                                                                        }
                                                                                    </Name>
                                                                                    {service?.is_video && (
                                                                                        <i className="fal fa-video"></i>
                                                                                    )}
                                                                                </CustomDiv>
                                                                                <ServiceDuration>
                                                                                    {`${
                                                                                        service.duration >=
                                                                                        60
                                                                                            ? Math.floor(
                                                                                                  service.duration /
                                                                                                      60
                                                                                              ) +
                                                                                              'h'
                                                                                            : ''
                                                                                    } ${
                                                                                        service.duration %
                                                                                        60
                                                                                    }${t('min')}`}
                                                                                </ServiceDuration>
                                                                            </div>
                                                                        </div>
                                                                    </ServicesList>
                                                                )
                                                            )}
                                                        </GroupWrapper>
                                                    </div>
                                                ))}
                                        </Wrapper>
                                    ) : (
                                        <div>
                                            {t('No Service available for this serviceProvider')}
                                        </div>
                                    ))
                                )}
                            </ServicesBody>
                        )}
                    </ServicesContent>
                </Row>
            </Container>
        </>
    );
};

const ServicesContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;

const CloseButton = styled.div`
    margin-right: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    line-height: 2.5rem;
`;

const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;

const ServicesBody = styled.div`
    padding: 0 1.75rem;
    overflow-y: auto;
    height: 44rem;
`;
const Wrapper = styled.div`
    display: grid;
    // grid-template-rows: repeat(2, minmax(0px, 1fr));
    gap: 1rem /* 16px */;
`;
const GroupWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 1rem /* 16px */;
`;

const ServicesList = styled.div`
    border-radius: 0.5rem !important;
    // padding: 26px 20px !important;
    position: relative;
    cursor: pointer !important;
`;

const Label = styled.div`
    background-color: ${palette.green};
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${palette.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

const Name = styled.div`
    font-weight: 500;
    font-size: 0.8rem;
    width: 140px;
    white-space: pre-wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const ServiceDuration = styled.div`
    font-size: 0.7rem;
`;

const CustomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const GroupTitle = styled.h3`
    text-align: center;
`;
export default SelectServices;
